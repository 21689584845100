import * as UI from './styles'
import { useCancelOrderMutation } from '../../redux/services/MerchantApi';
import { useParams } from 'react-router-dom';
import { Loading } from '../../components/Loading/Loading';
import { failStatuses, successStatuses } from '../../utils/orders_statuses';
import FailedBlock from './components/FailedBlock';
import SuccessBlock from './components/SuccessBlock';
import CopyButton from '../../components/CopyValue';
import { MANAGER_BOT } from '../../utils/text';
import { IReplenishmentProps } from './types';

const Replenishment = (props: IReplenishmentProps) => {
  const { order } = props;
  const {id} = useParams();

  const [cancelOrder, {isLoading: loading}] = useCancelOrderMutation();

  const { status, id: orderId, time_left, expected_income, requisite, currency_code_to } = order || {};

  const isSbp = requisite?.payment_type === 'SBP';
  const isTransfer = requisite?.payment_type ==='TRANSFER';
  const inactiveTimer = Number(order?.time_left) === 0;

  const activeTransfer = !successStatuses.includes(String(status)) && !failStatuses.includes(String(status))

  const handleCancel = async () => {
    if (!order) {
      return;
    }
    await cancelOrder(String(id));
    window.location.href = order.merchant_failed_page
  }

  const handleClickAction = () => {
    if (!order) {
      return;
    }
    else if (activeTransfer) {
      handleCancel();
    } else if (successStatuses.includes(String(status))) {
      const timer = setTimeout(() => {
        window.location.href = order?.merchant_success_page
      }, 5000);

      return () => {
        clearTimeout(timer);
        window.location.href = order?.merchant_success_page
      };
    } else {
      window.location.href = order?.merchant_failed_page
    }
  };

  const buttonText = activeTransfer ? 'Отменить платеж' : 'Вернуться на сайт'

  if (loading) {
    return <Loading/>
  }
  return (
    <UI.BlockReplenishment>
      <UI.ReplenishmentCard>
        <UI.HeaderContainer>
          <UI.HeaderFlex>
            <img src={'/download.svg'} alt={'replenishment'}/>
            <label>Пополнение</label>
          </UI.HeaderFlex>
          <UI.HeaderFlex>
            <h2>ID заказа:</h2>
            <h3 style={{color: '#83B812'}}>{orderId}</h3>
          </UI.HeaderFlex>
        </UI.HeaderContainer>
        <UI.TimerBlock>
          <UI.TimerInner>
            <UI.TimerFlex>
              {order?.bank_svg_image && <img src={order?.bank_svg_image} alt={'card'} style={{height: '24px', width: '24px'}}/>}
              <h3>{'fresh.casino'}</h3>
          </UI.TimerFlex>
            {time_left && !inactiveTimer ? <h2>{`${Math.floor(time_left / 60)}`.padStart(2, '0')}:{`${time_left % 60}`.padStart(2, '0')}</h2> : null}
          </UI.TimerInner>
          <h2>{expected_income} {currency_code_to}</h2>
          <UI.TimerFlexBetween>
              <h1>{'Без комиссии'}</h1>
            {activeTransfer &&
              <div style={{display: 'flex', gap: '8px'}}>
                <img src={'/iconDone.svg'} alt={'done'}/>
                <h2 style={{color: '#83B812'}}>Заявка активна</h2>
              </div>}
          </UI.TimerFlexBetween>
        </UI.TimerBlock>
        {activeTransfer && (
          <>
            <UI.TypePaymentStyles>
              <img src={isSbp ? '/sbp_container.svg' : '/payments.svg'} style={{width: '80px', height: '64px'}}
                   alt={'sbp'}/>
              <UI.TransitionDescription>
                <h1>
                  {isSbp
                    ? 'Оплата через СБП'
                    : 'Перевод на карту'}
                </h1>
                <h2>
                  {`Переведите ${expected_income} RUB в ${requisite?.bank.name}.
              Не оставляйте комментарий к переводу`}
                </h2>
              </UI.TransitionDescription>
            </UI.TypePaymentStyles>
            {isSbp ?
              <UI.DataField>
                <label>Номер телефона</label>
                <div style={{justifyContent: 'space-between'}}>
                  {requisite?.phone_number}
                  <CopyButton value={requisite?.phone_number}/>
                </div>
              </UI.DataField>
              : <UI.DataField>
                <label>Номер карты</label>
                <div style={{justifyContent: 'space-between'}}>
                  {requisite?.account_number}
                  <CopyButton value={requisite?.account_number}/>
                </div>
              </UI.DataField>}
            <UI.DataField>
              <label>Сумма перевода</label>
              <div style={{justifyContent: 'space-between'}}>
                {expected_income} {currency_code_to}
                <CopyButton value={String(expected_income)}/>
              </div>
            </UI.DataField>
            <UI.BankName>
              <UI.DataField>
                <label>Получатель</label>
                <div>{requisite?.fio}</div>
              </UI.DataField>
              <UI.DataField>
                <label>Банк получателя</label>
                <div>{requisite?.bank.name}</div>
              </UI.DataField>
            </UI.BankName>
            {isTransfer && <UI.BankName>
              <UI.DataField>
                <label>БИК</label>
                <div>{requisite?.bank.bic}</div>
              </UI.DataField>
              <UI.DataField>
                <label>Назначение перевода</label>
                <div>Перевод</div>
              </UI.DataField>
            </UI.BankName>}
            <UI.SupportBlockStyles>
              <UI.Warning/>
              <UI.SupportDescription>
                {'Переводите точную сумму одним переводом! Реквизиты для оплаты меняются каждый платеж! Если возник вопрос, задайте его в нашем чате'}
                <UI.SupportLink
                  to={MANAGER_BOT}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Чат техподдержки
                </UI.SupportLink>
              </UI.SupportDescription>
            </UI.SupportBlockStyles>
          </>)}
        {failStatuses.includes(String(status)) && <FailedBlock />}
        {successStatuses.includes(String(status)) && <SuccessBlock />}
        <UI.Button onClick={handleClickAction}>
          {buttonText}
        </UI.Button>
      </UI.ReplenishmentCard>
    </UI.BlockReplenishment>
  )
}

export default Replenishment