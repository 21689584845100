import * as UI from './styles'

const FailedBlock = () => {

  return (
    <UI.Container>
      <img src={'/failed.png'} alt={'fail'} />
      <UI.Title>
        Платёж не был завершён вовремя.
      </UI.Title>
      <UI.Description>
        Пожалуйста, вернитесь на сайт и повторите попытку. Если у вас возникли трудности с оплатой, обратитесь в службу поддержки.
      </UI.Description>
    </UI.Container>
  )
}

export default FailedBlock