import styles from "../styles.module.scss";
import { FC } from "react";

interface ITransferHeadProps {
  id: string;
}

export const TransferHead: FC<ITransferHeadProps> = ({id}) => {
  return (
    <div className={styles.payment_dialog__header}>
      <div className={styles.payment_dialog__header_title}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4C12.5523 4 13 4.44772 13 5V13.5858L15.2929 11.2929C15.6834 10.9024 16.3166 10.9024 16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L12 17.4142L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929L11 13.5858V5C11 4.44772 11.4477 4 12 4ZM5 16C5.55228 16 6 16.4477 6 17V19H18V17C18 16.4477 18.4477 16 19 16C19.5523 16 20 16.4477 20 17V21H4V17C4 16.4477 4.44772 16 5 16Z"
            fill="white"
          />
        </svg>
        <span>Пополнение</span>
      </div>
      <div className={styles.payment_dialog__header_id}>
        <span className={styles.payment_dialog__header_id_text}>ID заказа:</span>
        <span className={styles.payment_dialog__header_id_number}>{id}</span>
      </div>
    </div>
  )
}
