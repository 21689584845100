import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    font-size: 20px;
    font-weight: 700;
    font-family: GilroyBold,sans-serif;
    line-height: 24px;
    color: black;

    h2 {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        font-family: GilroyRegular,sans-serif;
    }

    h3 {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        font-family: GilroyBold,sans-serif;
        line-height: 18px;
    }

    @media (max-width: 768px) {
        font-size: 18px;
        h2, h3 {
            font-size: 12px;
        }
    }
    @media (max-width: 480px) {
        font-size: 18px;
        h2, h3 {
            font-size: 10px;
        }
    }
`

export const BlockReplenishment = styled.div`
    height: 100vh !important;
    background-color: #F8F6FA;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    color: #A7A7A7;
    justify-content: center;
    overflow-y: auto;
    background-image: url('/backGroundDesktop.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 768px) {
        padding: 12px;
    }
    @media (max-width: 480px) {
        padding: 8px;
    }
`

export const ReplenishmentCard = styled.div`
    margin: 16px 0;
    padding: 20px 28px 36px 28px;
    background-color: white;
    max-width: 490px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 10px #37296108;
    box-sizing: border-box;

    @media (max-width: 768px) {
        padding: 16px 20px 30px 20px;
        gap: 16px;
    }
    @media (max-width: 480px) {
        padding: 12px 16px 24px 16px;
        gap: 12px;
    }
`

export const TimerBlock = styled.div`
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px;
    background: linear-gradient(80deg, rgba(160, 178, 242, 0.60) 15.47%, rgba(236, 240, 255, 0.60) 84.53%);

    h3 {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        font-family: GilroyBold,sans-serif;
        color: black;
    }

    h2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
        color: black;
        margin-bottom: -12px;
        font-family: GilroyBold,sans-serif;
    }

    h1 {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        font-family: GilroyRegular,sans-serif;
        line-height: 18px;
        color: #474D3A;
    }
`

export const TypePaymentStyles = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
`

export const TransitionDescription = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    h1 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        color: #292925;
        align-items: start;
        font-family: GilroyBold,sans-serif;
    }

    h2 {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        font-family: GilroyRegular,sans-serif;
        line-height: 18px;
        color: #474D3A;
        align-items: start;
    }
`

export const SupportBlockStyles = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: GilroyRegular,sans-serif;
    line-height: 18px;
    color: #5A6446;
`

export const SupportDescription = styled.div`
    display: flex;
    padding: 8px 0;
    flex-direction: column;
    text-align: left;
    gap: 4px;
    flex: 1 0 0;
`

export const Warning = styled.div`
    width: 4px;
    align-self: stretch;
    border-radius: 4px 0 0 4px;
    background: #FF882E;
`

export const SupportLink = styled(Link)`
    width: fit-content;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    color: #83B812;
    font-family: GilroyRegular,sans-serif;
`

export const Button = styled.button`
    display: flex;
    height: 44px;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 6px;
    background: white;
    border: 1px solid #83B812;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    font-family: GilroyBold,sans-serif;
    padding: 10px;
    cursor: pointer;
`

export const DataField = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
    align-self: stretch;

    label {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        font-family: GilroyBold,sans-serif;
        color: #292925;
    }

    div {
        display: flex;
        flex: 1;
        padding: 16px;
        align-items: center;
        align-self: stretch;
        border-radius: 6px;
        background: #F2F2F2;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: black;
        font-family: GilroyRegular,sans-serif;
    }
`

export const BankName = styled.div`
    display: flex;
    gap: 12px;
    align-items: stretch;
`

export const HeaderFlex = styled.div`
    display: flex;
    gap: 8px;
`

export const TimerInner = styled.div`
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
`

export const TimerFlex = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`

export const TimerFlexBetween = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`