import { useParams } from "react-router-dom";
import { failStatuses, successStatuses } from "utils/orders_statuses";
import { TransferWarning } from "./components/TransferWarning";
import { TransferSuccess } from "./components/TransferSuccess";
import { TransferForm } from "./components/TransferForm";
import { FC } from "react";
import { TGetOrderResponse } from "redux/types/MerchantTypes";
import styles from './styles.module.scss';

interface ITransferPageProps {
  order?: TGetOrderResponse;
  isSuccess: boolean;
}


export const TransferPage: FC<ITransferPageProps> = ({order, isSuccess}) => {
  const {id} = useParams()

  if (!order || !id) {
    return <main className={styles.payment_page}/>
  }

  if (failStatuses.includes(String(order?.status))) {
    return <TransferWarning order={order} id={id}/>
  }

  if (successStatuses.includes(String(order?.status))) {
    return <TransferSuccess order={order} id={id}/>
  }

  return (
    <TransferForm order={order} isSuccess={isSuccess} id={id}/>
  )
}