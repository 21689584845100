import * as UI from './styles'

const SuccessBlock = () => {

  return (
    <UI.Container>
      <img src={'/success.png'} alt={'success'} />
      <UI.Title>
        Оплата прошла успешно!
      </UI.Title>
      <UI.Description>
        Мы перенаправим вас на сайт
        через 5 секунд
      </UI.Description>
    </UI.Container>
  )
}

export default SuccessBlock