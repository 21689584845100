import { MouseEvent, useState } from 'react'
import { ICopyButtonProps } from './types';

const CopyButton = (props: ICopyButtonProps) => {
  const {
    value = '',
  } = props;
  const [isValueCopy, setIsValueCopy] = useState<boolean>(false)

  const copyToClipboard = (value: string) => {
    navigator.clipboard
      .writeText(value).then(() => {
      setIsValueCopy(true)
    })
      .catch((e) => {
        console.error(e)
      })
  }

  const handleCopy = (e: MouseEvent) => {
    e.stopPropagation()
    copyToClipboard(value)
  }

  return <img src={isValueCopy ? '/copy-submitted-icon.png' : '/greenCopy.svg'} alt={'copy'} onClick={handleCopy} style={{ cursor: 'pointer', height: '24px', width: '24px' }} />
}

export default CopyButton
