import classNames from "classnames";
import { TransferLogo } from "./TransferLogo";
import { TransferHead } from "./TransferHead";
import { TransferCard } from "./TransferCard";
import CopyToClipboard from 'react-copy-to-clipboard';
import { FC, useEffect, useMemo, useState } from "react";
import { TGetOrderResponse } from "redux/types/MerchantTypes";
import styles from "../styles.module.scss";

enum EPaymentType {
  PAYMENT_TYPE_C2C = 'C2C',
  PAYMENT_TYPE_SBP = 'SBP',
}
const TIME_SHOW_COPY_TEXT = 2 * 1000;

interface ITransferFormProps {
  order: TGetOrderResponse;
  isSuccess: boolean;
  id: string;
}

export const TransferForm: FC<ITransferFormProps> = ({order, id}) => {
  const [isCopyNumber, setIsCopyNumber] = useState(false);
  const [isCopySum, setIsCopySum] = useState(false);
  const [timer, setTimer] = useState<any>(null);

  const copyNumber = () => {
    clearTimeout(timer);
    setIsCopySum(false);
    setIsCopyNumber(true);
    const _timer = setTimeout(() => {setIsCopyNumber(false)}, TIME_SHOW_COPY_TEXT);
    setTimer(_timer);
  }

  const copySum = () => {
    clearTimeout(timer);
    setIsCopyNumber(false);
    setIsCopySum(true);
    const _timer = setTimeout(() => {setIsCopySum(false)}, TIME_SHOW_COPY_TEXT);
    setTimer(_timer);
  }

  const formatPhoneNumber = (phoneNumber: string | undefined) => {
    if (!phoneNumber) return '';

    const phoneStr = phoneNumber.toString();
    let cleanedPhoneStr = phoneStr;

    let formattedNumber = '';

    if (phoneStr.startsWith('+7')) {
      formattedNumber += '+';
      cleanedPhoneStr = phoneStr.slice(1);
    }

    const part1 = cleanedPhoneStr.slice(0, 1);
    const part2 = cleanedPhoneStr.slice(1, 4);
    const part3 = cleanedPhoneStr.slice(4, 7);
    const part4 = cleanedPhoneStr.slice(7, 9);
    const part5 = cleanedPhoneStr.slice(9, 11);


    formattedNumber += `${part1} ${part2} ${part3} ${part4} ${part5}`;

    return formattedNumber;
  };

  const copyPhone = useMemo(() => {
    const {payment_type, phone_number, account_number} = order.requisite;
    return payment_type === EPaymentType.PAYMENT_TYPE_C2C ? (account_number?.toString() || '') : (phone_number?.toString() || '')
  }, [order?.requisite]);

  const phone = useMemo(() => {
    const {payment_type, phone_number, account_number} = order.requisite;
    return payment_type === EPaymentType.PAYMENT_TYPE_C2C
      ? (account_number?.toString() || '')
      : (formatPhoneNumber(phone_number?.toString() || ''))
  }, [order?.requisite]);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    }
  }, [timer])

  return (
    <main className={styles.payment_page}>
      <TransferLogo/>
      <div className={styles.payment_dialog}>
        <TransferHead id={id}/>
        <div className={styles.payment_dialog__body}>
          <TransferCard
            isShowStatus time={order?.time_left}
            price={order?.expected_income}
            code={order?.currency_code_to}
            status={order?.payment_form}
          />
          <div className={styles.payment_dialog__platform}>
            <div className={styles.payment_dialog__platform_wr}>
              <div className={styles.payment_dialog__platform_image_wr}>
                <img src={order.bank_svg_image} alt="" className={styles.payment_dialog__platform_image}/>
              </div>
              <div className={styles.payment_dialog__platform_content}>
                <div className={styles.payment_dialog__platform_title}>
                  {order?.requisite.payment_type === EPaymentType.PAYMENT_TYPE_SBP && 'Оплата через СБП'}
                  {order?.requisite.payment_type === EPaymentType.PAYMENT_TYPE_C2C && 'Перевод на карту'}
                </div>
                <div className={styles.payment_dialog__platform_text}>
                  Переведите {order?.expected_income} RUB в {order?.requisite?.bank?.name}. <br/> Не оставляйте комментарий к переводу
                </div>
              </div>
            </div>
            <div className={styles.payment_dialog__platform_text}>
              Переведите {order?.expected_income} RUB в {order?.requisite?.bank?.name}. <br/> Не оставляйте комментарий к переводу
            </div>
          </div>
          <form action="#" className={styles.payment_dialog__form}>
            <label className={styles.input_field}>
              <div className={styles.input_field_label}>
                {order?.requisite.payment_type === EPaymentType.PAYMENT_TYPE_SBP && 'Номер телефона'}
                {order?.requisite.payment_type === EPaymentType.PAYMENT_TYPE_C2C && 'Номер карты'}
              </div>
              <div className={styles.input_field__wr}>
                <input
                  type="text"
                  className={styles.input_field__input}
                  value={phone}
                  disabled
                />
                <CopyToClipboard
                  text={copyPhone}
                  onCopy={copyNumber}
                >
                  <div className={styles.input_field__copy}>
                    {isCopyNumber ? 'Скопировано' : 'Копировать'}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 5.11765C3 3.9481 4.00736 3 5.25 3H15.375C16.6176 3 17.625 3.9481 17.625 5.11765V6.17647C17.625 6.76124 17.1213 7.23529 16.5 7.23529C15.8787 7.23529 15.375 6.76124 15.375 6.17647V5.11765L5.25 5.11765V15.7059H6.375C6.99632 15.7059 7.5 16.1799 7.5 16.7647C7.5 17.3495 6.99632 17.8235 6.375 17.8235H5.25C4.00736 17.8235 3 16.8754 3 15.7059V5.11765ZM8.625 10.4118C8.625 9.24222 9.63236 8.29412 10.875 8.29412H18.75C19.9926 8.29412 21 9.24222 21 10.4118V18.8824C21 20.0519 19.9926 21 18.75 21H10.875C9.63236 21 8.625 20.0519 8.625 18.8824V10.4118ZM18.75 10.4118H10.875V18.8824H18.75V10.4118Z"
                        fill="#F4D483"
                      />
                    </svg>
                  </div>
                </CopyToClipboard>
              </div>
            </label>
            <label className={styles.input_field}>
              <div className={styles.input_field_label}>Сумма перевода</div>
              <div className={styles.input_field__wr}>
                <input
                  type="text"
                  className={styles.input_field__input}
                  value={`${order?.expected_income} ${order?.currency_code_to}`}
                  disabled
                />
                <CopyToClipboard
                  text={order?.expected_income.toString() || ''}
                  onCopy={copySum}
                >
                  <div className={styles.input_field__copy}>
                    {isCopySum? 'Скопировано' : 'Копировать'}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 5.11765C3 3.9481 4.00736 3 5.25 3H15.375C16.6176 3 17.625 3.9481 17.625 5.11765V6.17647C17.625 6.76124 17.1213 7.23529 16.5 7.23529C15.8787 7.23529 15.375 6.76124 15.375 6.17647V5.11765L5.25 5.11765V15.7059H6.375C6.99632 15.7059 7.5 16.1799 7.5 16.7647C7.5 17.3495 6.99632 17.8235 6.375 17.8235H5.25C4.00736 17.8235 3 16.8754 3 15.7059V5.11765ZM8.625 10.4118C8.625 9.24222 9.63236 8.29412 10.875 8.29412H18.75C19.9926 8.29412 21 9.24222 21 10.4118V18.8824C21 20.0519 19.9926 21 18.75 21H10.875C9.63236 21 8.625 20.0519 8.625 18.8824V10.4118ZM18.75 10.4118H10.875V18.8824H18.75V10.4118Z"
                        fill="#F4D483"
                      />
                    </svg>
                  </div>
                </CopyToClipboard>
              </div>
            </label>
            <div className={styles.payment_dialog__form_box}>
              <label className={styles.input_field}>
                <div className={styles.input_field_label}>Получатель</div>
                <input type="text" className={styles.input_field__input} value={order.requisite.fio} disabled/>
              </label>
              <label className={styles.input_field}>
                <div className={styles.input_field_label}>Банк получателя</div>
                <input
                  type="text"
                  className={styles.input_field__input}
                  value={order?.requisite.bank.name || 'Банк не найден'}
                  disabled
                />
              </label>
            </div>
          </form>
          <div className={styles.attention_block}>
            <div className={styles.attention_block__wr}>
              <div className={styles.attention_block__content_wr}>
                <p className={styles.attention_block__text}>
                  Переводите точную сумму одним переводом! <br/> Реквизиты для оплаты меняются каждый платеж!
                  {/*Если возник вопрос, задайте его в нашем чате.*/}
                </p>
                {/*<a href="#" className={styles.attention_block__link}> Чат техподдержки </a>*/}
              </div>
            </div>
          </div>
          <a href={order?.merchant_failed_page} className={classNames(styles.btn, styles.btn_secondary)}>Отменить платеж</a>
        </div>
      </div>
    </main>
  )
}