import classNames from "classnames";
import errorImg from "assets/img/error-image.png";
import { TransferLogo } from "./TransferLogo";
import { TransferHead } from "./TransferHead";
import { TransferCard } from "./TransferCard";
import { FC } from "react";
import { TGetOrderResponse } from "redux/types/MerchantTypes";
import styles from "../styles.module.scss";

interface ITransferWarningProps {
  order: TGetOrderResponse;
  id: string;
}

export const TransferWarning: FC<ITransferWarningProps> = ({order, id}) => {
  return (
    <main className={styles.payment_page}>
      <TransferLogo/>
      <div className={styles.payment_dialog}>
        <TransferHead id={id}/>
        <div className={styles.payment_dialog__body}>
          <TransferCard
            status={order?.payment_form}
            time={order?.time_left || 0}
            price={order?.expected_income}
            code={order?.currency_code_to}
          />
          <div className={styles.status_block}>
            <div className={styles.status_block__image_wr}><img src={errorImg} alt=""/></div>
            <div className={styles.status_block__title}>Платёж не был завершён вовремя.</div>
            <div className={styles.status_block__description}>
              Пожалуйста, вернитесь на сайт и повторите попытку.
              {/*Если у вас возникли трудности с оплатой, обратитесь в <a href="#">службу поддержки.</a>*/}
            </div>
          </div>
          <a href={order?.merchant_failed_page} className={classNames(styles.btn, styles.btn_secondary)}>Вернуться на сайт</a>
        </div>
      </div>
    </main>
  )
}