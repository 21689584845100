import React, { useState } from 'react'
// @ts-ignore
import styles from './FailTransfer.module.css'
import { Button } from '../../../../../components/Button/Button'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useParams } from 'react-router-dom'
import { TStatusProps } from '../../TransferStatus'
import Container from "../../../../../components/MainContainerWithWindow/Container";

export const FailTransfer = (props : TStatusProps) => {
  const {url} = props
  const { id } = useParams()
  const [isCopyAlert, setIsCopyAlert] = useState<boolean>(false)
  const handleCopyClick = () => {
    setIsCopyAlert(true)
    setTimeout(() => {
      setIsCopyAlert(false)
    }, 2000)
  }
  const handleNavigate = () => {
    window.location.href = url;
  }
  return (
    <Container>
      <div className={styles.wrapper}>
        <center>
          <img className={styles.statusImg} src={'/fail_status_icon.png'} alt={'ok status icon'} />
          <h1>Извините, платеж не был совершен</h1>
        </center>
        <div className={styles.block}>
          <span>Номер заявки</span>
          <p>
            {id}
            <CopyToClipboard text={id || ''}>
              <img
                src={isCopyAlert ? '/copy-submitted-icon.png' : '/copy_icon.png'}
                alt={'copy icon'}
                onClick={() => {
                  handleCopyClick()
                }}
              />
            </CopyToClipboard>
          </p>
        </div>
        <Button
          action={() => handleNavigate()}
          text={'Вернуться на сайт партнера'}
          style={{ width: '100%', marginBottom: '8px' }}
        />
        <center>
          {/*<img src={'./container-logo.svg'} alt={'logo'} className={styles.logo} style={{width: 'fit-content', height: '35px'}}/>*/}
        </center>
      </div>
    </Container>
  )
}
