import { addZero } from "utils/text";
import cardImg from "assets/img/card-icon.png";
import styles from "../styles.module.scss";
import { FC, useMemo } from "react";

interface IPaymentCardProps {
  isShowStatus?: boolean,
  time: number,
  price: number,
  code: string
  status: string
}

export const TransferCard: FC<IPaymentCardProps> = ({
  isShowStatus,
  time,
  price,
  code,
  status,
}) => {
  const formatTime = useMemo(() => `${addZero(time / 60 | 0)}:${addZero(time % 60)}`, [time]);
  return (
    <div className={styles.payment_dialog__card}>
      <div className={styles.payment_dialog__card_top}>
        <div className={styles.payment_dialog__card_name}>
          <img src={cardImg} alt=""/>
          <span>{status}.casino</span>
        </div>
        {time > 0 && <div className={styles.payment_dialog__card_time}>{formatTime}</div>}
      </div>
      <div className={styles.payment_dialog__card_balance}>{price} {code}</div>
      <div className={styles.payment_dialog__card_bottom}>
        <div className={styles.payment_dialog__card_commission}>Без комиссии</div>
        {isShowStatus &&
          <div className={styles.payment_dialog__card_status}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7071 4.27847C14.0976 4.64977 14.0976 5.25177 13.7071 5.62307L7 12L2.29289 7.52461C1.90237 7.15331 1.90237 6.55132 2.29289 6.18002C2.68342 5.80872 3.31658 5.80872 3.70711 6.18002L7 9.31081L12.2929 4.27847C12.6834 3.90718 13.3166 3.90718 13.7071 4.27847Z"
                fill="#20CA4F"
              />
            </svg>
            <span>Заявка активна</span>
          </div>
        }
      </div>
    </div>
  )
}
