import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
`

export const Title = styled.div`
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    font-family: GilroyBold,sans-serif;
    line-height: 24px;
    color: black;
`

export const Description = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-family: GilroyRegular,sans-serif;
    color: #474D3A;
`