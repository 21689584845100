import { useGetMerchantOrderQuery } from '../../redux/services/MerchantApi';
import { useParams } from 'react-router-dom';
import { TransferPage } from "../Merchant/Transfer";
import { Transfer } from "../Merchant/Transfer/Transfer";
import Replenishment from "../Replenishment";

enum EPaymentForm {
  FRESH = 'fresh',
  LEX = 'lex',
}
const LandingRenderContent = () => {
  const {id} = useParams();
  const {
    isSuccess,
    data: order,
  } = useGetMerchantOrderQuery(id!, {pollingInterval: 1000});

  if (order?.payment_form === EPaymentForm.FRESH) {
    return <Replenishment order={order}/>
  }

  if (order?.payment_form === EPaymentForm.LEX) {
    return <TransferPage order={order} isSuccess={isSuccess} />
  }

  return <Transfer />;
}

export default LandingRenderContent;