import classNames from "classnames";
import successImg from "assets/img/success-image.png";
import { TransferLogo } from "./TransferLogo";
import { TransferHead } from "./TransferHead";
import { TransferCard } from "./TransferCard";
import { FC, useEffect, useMemo, useState } from "react";
import { TGetOrderResponse } from "redux/types/MerchantTypes";
import styles from "../styles.module.scss";
import { useNavigate } from "react-router-dom";

interface ITransferSuccessProps {
  order: TGetOrderResponse;
  id: string;
}

const COUNT_SECONDS = 5

export const TransferSuccess: FC<ITransferSuccessProps> = ({order, id}) => {
  const [second, setSecond] = useState<number>(COUNT_SECONDS);
  const navigate = useNavigate()

  const locateWordSecond = useMemo(() => {
    if (second > 4) return 'секунд';
    if (second > 1) return 'секунды';
    return 'секунду';
  }, [second]);

  useEffect(() => {
    const _timer = setInterval(() => {
      setSecond((prevSecond) => {
        if (prevSecond > 1) {
          return prevSecond - 1;
        } else {
          clearInterval(_timer);
          window.location.replace(order?.merchant_success_page);
          return prevSecond;
        }
      });
    }, 1000);

    return () => {
      clearInterval(_timer);
    };
  }, [second, navigate, order?.merchant_success_page]);

  return (
    <main className={styles.payment_page}>
      <TransferLogo/>
      <div className={styles.payment_dialog}>
        <TransferHead id={id}/>
        <div className={styles.payment_dialog__body}>
          <TransferCard
            time={order?.time_left || 0}
            price={order?.expected_income}
            code={order?.currency_code_to}
            status={order?.payment_form}
          />
          <div className={styles.status_block}>
            <div className={styles.status_block__image_wr}><img src={successImg} alt=""/></div>
            <div className={styles.status_block__title}>Оплата прошла успешно!</div>
            <div className={styles.status_block__description}>Мы перенаправим вас на сайт через { second } { locateWordSecond }</div>
          </div>
          <a href={order?.merchant_success_page} className={classNames(styles.btn, styles.btn_secondary)}>Вернуться на сайт</a>
        </div>
      </div>
    </main>
  )
}