import Main from "./pages/Main/Main";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import TransferStatus from "./pages/Merchant/TransferStatus/TransferStatus";
import { CreateOrder } from "./pages/Merchant/CreateOrder/CreateOrder";
import MerchantAuth from "./pages/Merchant/MerchantAuth/MerchantAuth";
import {
  CREATE_ORDER,
  DISPUTE_PAYMENT,
  FORBIDDEN_ERROR,
  MERCHANT_AUTH,
  NOT_FOUND_ERROR,
  SERVER_ERROR,
  TRANSFER,
  TRANSFER_STATUS
} from './utils/routes';
import { ProtectedRouterMerchant } from "./services/ProtectedRouterMerchant";
import { NotFoundError } from "./pages/Errors/NotFoundError";
import React from "react";
import { ForbiddenError } from "./pages/Errors/ForbiddenError";
import { ServerError } from "./pages/Errors/ServerError";
import DisputePayment from "./pages/Disputes";
import LandingRenderContent from './pages/LandingRenderContent';

function App() {
  return (
    <Router>
      <Routes>
        <Route path={DISPUTE_PAYMENT} element={<DisputePayment/>}/>
        <Route path={'*'} element={<Main/>}/>
        <Route path={''} element={<Main/>}/>
        <Route path={''} element={<ProtectedRouterMerchant/>}>
          <Route path={`${MERCHANT_AUTH}`} element={<MerchantAuth/>}/>
          <Route path={`${CREATE_ORDER}`} element={<CreateOrder/>}/>
        </Route>
        <Route path={`${TRANSFER}/:id`} element={<LandingRenderContent/>}/>
        <Route path={`${TRANSFER_STATUS}/:id`} element={<TransferStatus/>}/>
        <Route path={NOT_FOUND_ERROR} element={<NotFoundError/>}/>
        <Route path={SERVER_ERROR} element={<ServerError/>}/>
        <Route path={FORBIDDEN_ERROR} element={<ForbiddenError/>}/>
      </Routes>
    </Router>
  );
}

export default App;
