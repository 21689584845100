import styles from "../styles.module.scss";

export const TransferLogo = () => {
  return (
    <a href="#" className={styles.payment_page__logo}>
      <svg width="69" height="30" viewBox="0 0 69 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.9853 0H69L59.3603 14.246L69 30H67.9853L58.3456 14.246L57.5744 15.466L66.4632 30H65.4485L57.0569 16.28L56.2807 17.499L63.9265 30H62.9118L55.7632 18.312L48.3355 29.982H43.1423L53.1594 14.242L43.1423 0H48.3355L55.7632 10.56L62.9118 0H63.9265L56.2796 11.294L57.0559 12.396L65.4485 0H66.4632L57.5724 13.132L58.3537 14.242L67.9853 0ZM0 30V0.018H4.97307V25.796H17.8548V30H0ZM40.3082 30H23.0988V0.018H40.3082V4.16H28.0718V12.322H39.538V16.424H28.0718V25.837H40.3082V30Z"
          fill="#F4D483"
        />
      </svg>
    </a>
  )
}